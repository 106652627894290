<template>
  <div class="devcomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label=" 日志起始结束时间">
              <el-date-picker v-model="excelTime" type="daterange" align="right" unlink-panels range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="  ">
              <el-input style="width: 420px" placeholder="15位完整设备编号" v-model="searchsn"
                        maxlength="15" clearable @keyup.enter.native="onRefresh()">
                <template slot="prepend">输入设备IMEI(串号)：</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search"></el-button>
              </el-input>
            </el-form-item>

            <el-form-item label="日志类型筛选：">
              <el-select clearable filterable @change="onRefresh"  v-model="ssvalue" placeholder="请选择" style="width: 150px" >
                <el-option v-for="item in sselect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>


            <el-form-item label="  ">
              <el-checkbox v-model="reverseFlag" @change="onRefresh">逆序</el-checkbox>
            </el-form-item>

            <el-form-item label="  ">
              <el-button type="success" @click="onRefresh" icon="el-icon-search">查 询 日 志</el-button>
            </el-form-item>

            <el-form-item label="  ">
              <el-button type="primary" @click="ExportLogExcel" icon="el-icon-download">导出Excel文件</el-button>
            </el-form-item>


          </el-form>

        </div>
        <div class="search">


        </div>

        <el-table :data="tbdata" border stripe  style="width: 100%; margin: 3px">
          <el-table-column prop="id" align="center" label="日志编号" width="130">
          </el-table-column>

          <el-table-column prop="server" align="center" label="服务器" width="125">
          </el-table-column>


          <el-table-column prop="type" align="center" label="日志类型" width="125">
          </el-table-column>


          <el-table-column prop="time" align="center" label="时间" width="165">
          </el-table-column>


          <el-table-column prop="uart" align="center" label="串口数据" width="165">
          </el-table-column>


          <el-table-column prop="parse" label="数据解析" align="left">
          </el-table-column>

<!--          <el-table-column prop="reverse" align="center" label="预留字段" width="165">-->
<!--          </el-table-column>-->

        </el-table>


      </el-col>


    </el-row>


  </div>
</template>

<script>
export default {
  data() {
    return {
      searchsn:"",//按SN查找
      reverseFlag:false,
      tbdata: [],
      sselect: [{
        value: '',
        label: '不限'
      },
        {
          value: '运行数据',
          label: '运行数据'
        },
        {
          value: '工作数据',
          label: '工作数据'
        },
        {
          value: '状态数据',
          label: '状态数据'
        },
    ],
      ssvalue: '',

      excelTime: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 366);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      //device list excel
      deviceHeader:
      {
        id: '序号',
        server: '服务器',
        type: '日志类型',
        time: '时间',
        uart: '串口数据',
        parse: '数据解析',
        // reserve: '预留字段',
      },

    }
  },
  created() {
    this.info = this.$store.state.userInfo
    this.onLogTypeList()
  },
  mounted() {

  },
  beforeDestroy() {
  },
  methods: {

    //////////////++++++++++++++//////////  excel //++++++++++++++++++++++//////////////////

    ExportLogExcel() {
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备日志-" + this.searchsn + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.deviceHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.deviceHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.deviceHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 90
      }
      widthMap['A'] = 100
      widthMap['D'] = 130
      widthMap['E'] = 120
      widthMap['F'] = 200

      let colConf = this.$Excel.makeColConfig({ ...widthMap }, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: 'dcdcdc' } },
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
        }
      )
      ///////////////// left

      this.$Excel.setExportCellStyle(
          map,
          `F2:${end}`,
          {
            s: {
              alignment: { horizontal: 'left', vertical: 'center' },
            },
          },
          function (
              cell,
              newCell,
              row,
              config,
              currentRow,
              currentCol,
              fieldKey
          ) {
            console.log(row, config, currentCol, fieldKey)
            // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
            return newCell
          }
      )

      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '67c23a' } },
            font: { color: { rgb: 'ffffff' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          return currentRow === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },


    //////
    // 主刷新
    async onRefresh() {
      let res = await this.$api.device_log({
        sn:this.searchsn+"",
        status: this.ssvalue+"",
        start: this.excelTime[0]+"",
        end: this.excelTime[1]+"",
        reverse:this.reverseFlag,
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
      }
    },

    async onLogTypeList() {
      let res = await this.$api.log_type_list()
      console.log(res)
      if (res.status == 1) {
        if (res.u != null)
          this.sselect = [{
            value: '',
            label: '不限'
          }, ...res.u]
      }
    },

    countFormatter(row, column, cellValue) {
      return cellValue + '次';
    },


  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}
</style>
